import React from "react";
import SeriesUser from "../Series/SeriesUser";
import MoviesUser from "../Movies/MoviesUser";
import FriendsList from "../Friends/FriendsList";
import Hero4 from "../Hero4/Hero4";

const Profil = ({ color }) => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
    <Hero4/>
      <div className="flex flex-wrap py-6 px-6">
        <div className="w-full">
          <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row gap-4 px-4" role="tablist">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a className={ "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " + (openTab === 1 ? "bg-gray-400" : "text-white bg-gray-600") } onClick={e => { e.preventDefault(); setOpenTab(1); }} data-toggle="tab" href="#link1" role="tablist">Gestion de mes séries</a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a className={ "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " + (openTab === 2 ? "bg-gray-400" : "text-white bg-gray-600") } onClick={e => { e.preventDefault(); setOpenTab(2); }} data-toggle="tab" href="#link2" role="tablist">Gestion de mes films</a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={ "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " + (openTab === 3 ? "bg-gray-400" : "text-white bg-gray-600") } onClick={e => { e.preventDefault(); setOpenTab(3); }} data-toggle="tab" href="#link3" role="tablist">Mes amis
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <SeriesUser/>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <MoviesUser/>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <FriendsList/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profil;