import React from 'react';


const Hero2 = () => {
    return (
    <section className="text-blueGray-700 bg-black flex items-center text-gray-50">
      <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
        <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
          <h1 className="mb-8 text-2xl font-black tracking-tighter md:text-5xl title-font"> Regardez où et quand vous le voulez. </h1>
            <ul className="mb-8 text-base leading-relaxed text-left text-blueGray-600 text-xl "> 
              <li className="p-2">Organisez vos visionnages, retrouvez votre contenu, ne perdez plus le fil de vos séries et films. </li>
              <li className="p-2">Regroupez les visionnages de tous vos services de VOD au même endroits... </li>
              <li className="p-2">Gérez votre planning, partagez vos derniers épisodes regardés, et découvrez des nouveautés… </li>
              <li className="p-2">Le tout entouré de la plus grosse communauté francophone de films et séries. </li>
            </ul>
        </div>
        <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
          <img className="object-cover object-center rounded-lg " alt="hero" src="https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/tv.png"/>
        </div>
      </div>
    </section>
    )
}


export default Hero2;