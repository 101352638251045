import axios from "axios";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Hero2 from "../Hero2/Hero2";

const Allseries = ({ match }) => {

    let id = match.params.id;

    const [addSuccess, setAddSuccess] = useState(false)
    const [errors, setErrors] = useState([]);

    const addShow = async (event) => {
        event.preventDefault();

        let betaseriesURL = process.env.REACT_APP_API_URL+"shows/show";

        await axios.post(betaseriesURL, {
            key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
            v: 3.0,
            id: id,
            token: localStorage.getItem("token")
        }).then(res => setAddSuccess(true))
        .catch((error) => {
            setErrors(error.response.data.errors[0].text);
        });
    };    

    const GetShows = (NbOffset = 0) => {
        axios.get(betaseriesURLShows, {
            params: {
                key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                v: 3.0,
                limit:30,
                offset:NbOffset,
            }
        }).then(response => {
            setShows(oldArray => [...oldArray, ...response.data.shows]);
            setOffset(NbOffset + 100)
        }).catch(error => {
        });
    }

    let betaseriesURLShows = process.env.REACT_APP_API_URL+"shows/discover";
    
    const [offset, setOffset] = useState(0);

    const [shows, setShows] = useState([]);
    useEffect(() => {
        GetShows ()
    }, []);


    return (
        <div>
        <Hero2/>
            <div className="min-h-screen py-32 px-10 bg-cover bg-no-repeat md:bg-bottom" >
                <h2 className="mb-2 text-center text-3xl font-extrabold leading-tight text-gray-900">Toutes les séries</h2>
                <p className="mb-20 text-center text-lg text-gray-500">Ici, vous pouvez ajouter vos contenus visionnés à votre compte.</p>
                {addSuccess &&
                <p className="text-center fs-5">La série a bien été ajouté.</p> }
                { errors && 
                <p className="text-center fs-5">{errors}</p> }
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 xl-grid-cols-4 gap-y-10 gap-x-6"> 

                {
                        shows.map((item) => {
                        return (
                            <div className="container mx-auto shadow-lg rounded-lg max-w-md hover:shadow-2xl transition duration-300 mt-8 bg-white">
                                <Link to={"/shows/"+item.id}>
                                    <img src={item.images.poster} alt="titre" className="hover:opacity-75 rounded-t-lg transition ease-in-out duration-150"/>
                                </Link>
                                <div className="mt-2 p-6">
                                <Link to={"/shows/"+item.id} className="text-lg mt-2 hover:text-gray-300">{item.title}</Link>
                                <div className="flex items-center text-gray-400 text-sm mt-1">
                                    <svg className="fill-current text-orange-500 w-4" viewBox="0 0 24 24">
                                    <g data-name="Layer 2">
                                        <path
                                        d="M17.56 21a1 1 0 01-.46-.11L12 18.22l-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18z"
                                        data-name="star"></path>
                                    </g>
                                    </svg>
                                    <span className="ml-1">Note : {item.notes.mean.toFixed(2)} / 5</span>
                                    <span className="mx-2">|</span>
                                    <span className="ml-1">Followers : {item.followers}</span>
                                </div>
                                <div className="flex items-center text-gray-400 text-sm mt-1">
                                    <span className="ml-1">Date de sortie : {item.creation}</span>
                                    <span className="mx-2">|</span>
                                    <span className="ml-1">Disponible sur : {item.network}</span>
                                </div>
                            <p className="mt-4 text-md text-gray-600">{item.description.length > 250 ?`${item.description.substring(0, 240)}...` : item.description}</p>
                                <div className="flex justify-between items-center">
                                    <div className="mt-4 flex items-center space-x-4 py-6">
                                        <Link to={"/shows/"+item.id} className="inline-flex items-center font-semibold text-gray-600 md:mb-2 lg:mb-0 hover:text-gray-400 " title="read more"> En savoir plus » </Link>
                                    </div>
                                    <button onClick={addShow} className="p-6 bg-gray-600 rounded-full h-4 w-4 flex items-center justify-center text-2xl text-white mt-4 shadow-lg hover:bg-gray-400 focus:shadow-outline focus:outline-none cursor-pointer"> +</button>
                                </div>
                                </div>
                            </div>
                            )
                        })
                        
                    }
                    </div>
                    <div className="flex justify-center pt-12 max-w-xl mx-auto">
                    <button onClick={e => GetShows (offset)} className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg text-white hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"> Voir plus</button>
                    </div></div>
            </div>
    )
}

export default Allseries;