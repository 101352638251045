import React, {useEffect, useState} from "react";
import axios from "axios";

const Series = ({ match }) => {

    let id = match.params.id;

    const [series, setSeries ] = useState([])
    const [picture, setPicture] = useState([]);
    const [errors, setErrors] = useState([]);
    const [episodes, setEpisodes] = useState([]);
    const [archiveSuccess, setArchiveSuccess] = useState(false)
    const [addSuccess, setAddSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const addShow = async (event) => {
        event.preventDefault();

        let betaseriesURL = process.env.REACT_APP_API_URL+"shows/show";

        await axios.post(betaseriesURL, {
            key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
            v: 3.0,
            id: id,
            token: localStorage.getItem("token")
        }).then(res => setAddSuccess(true))
        .catch((error) => {
            setErrors(error.response.data.errors[0].text);
        });
    };    

    const handleArchive = async (event) => {
        event.preventDefault();

        let betaseriesURL = process.env.REACT_APP_API_URL+"shows/archive";

        await axios.post(betaseriesURL, {
            key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
            v: 3.0,
            id: id,
            token: localStorage.getItem("token")
        }).then(res => setArchiveSuccess(true))
        .catch((error) => {
            setErrors(error.response.data.errors[0].text);
        });
    };    

    useEffect(() => {
        const getSeries = async () => {

            let betaseriesURL = process.env.REACT_APP_API_URL+"shows/display";

            const response =  await axios.get(betaseriesURL, {
                params: {
                    key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                    v: 3.0,
                    id: id
                }
            })
            setSeries(response.data.show)
            setPicture(response.data.show.images);
            setIsLoading(false)
        }
        getSeries()
    }, [id])

    const getEpisodes = async (event) => {
        event.preventDefault()
        let betaseriesURL = process.env.REACT_APP_API_URL+"shows/episodes";

        const response =  await axios.get(betaseriesURL, {
            params: {
                key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                v: 3.0,
                id: id,
                season: event.target.dataset.season

            }
        })
        setEpisodes(response.data.episodes)
    }

    const season = (nbSeason) => {

        return ( 
                <div data-season={nbSeason} onLoad={getEpisodes}>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 xl-grid-cols-4 gap-y-10 gap-x-6 mb-16">
                     {    
                        episodes.map((item) => {
                            return (
                                <div className="items-center container py-6 px-6 bg-white max-w-md mx-auto shadow-lg shadow hover:shadow-2xl transition duration-300 mt-8 rounded-lg">
                                        <p className="uppercase text-xs tracking-wide font-bold text-grayblue-200">{item.code}</p>    
                                        <h2 className="font-bold text-2xl text-black">{item.title}</h2>
                                        <div className="flex items-center text-gray-400 text-sm mt-1">
                                        <span className="ml-1">Note :  {item.note.mean.toFixed(2)}/5</span>
                                        <span className="mx-2">|</span>
                                        <span className="ml-1">Reviews : {item.comments}</span>
                                        <span className="mx-2">|</span>
                                        <span className="ml-1">Date de sortie : {item.date}</span>
                                        </div>
                                        <p className="text-sm leading-tight my-4 font-medium max-w-sm mr-8 text-black">{item.description}</p> 
                                </div>
                            )
                        })
                    }
                    </div>
                </div>)
    }

    const printSeason = () => {
        let nbSeason = series.seasons;
        let res = []

        for (let i = 1; i <= nbSeason; i++){
            res[i] = season(i)
        }

        return res
    }

    return isLoading ? <span>chargement</span> : (

        <div className="movie-info items-center">
            <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row">
                <div className="container flex flex-wrap">
                    <img className="rounded-xl" src={picture.poster} alt="série"/>
                </div>
                <div className="md:ml-24">
                    <h2 className="text-4xl font-semibold p-3">{series.original_title}</h2>
                    <div className="flex flex-wrap items-center text-gray-400 text-sm">
                        <svg className="fill-current text-orange-500 w-4" viewBox="0 0 24 24">
                            <g data-name="Layer 2">
                                <path d="M17.56 21a1 1 0 01-.46-.11L12 18.22l-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18z" data-name="star"></path>
                            </g>
                        </svg>
                        <span className="ml-1">Note : {series.notes.mean.toFixed(2)} / 5</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Followers : {series.followers}</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Date de sortie : {series.creation}</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Disponible sur : {series.network}</span>
                    </div>
                    <div className="flex flex-wrap items-center text-gray-400 text-sm">
                        <span className="ml-1">Classification : {series.rating}</span>
                        <span className="mx-2">|</span> 
                        <span className="ml-1">Nationalité : {series.country}</span>
                    </div>
                    <div className="py-4">
                        <div class="inline-block relative py-1 text-sm">
                            <div class="absolute inset-0 text-yellow-200 flex">
                                <svg height="100%" viewBox="0 0 50 100">
                                    <path
                                        d="M49.9,0a17.1,17.1,0,0,0-12,5L5,37.9A17,17,0,0,0,5,62L37.9,94.9a17.1,17.1,0,0,0,12,5ZM25.4,59.4a9.5,9.5,0,1,1,9.5-9.5A9.5,9.5,0,0,1,25.4,59.4Z"
                                        fill="currentColor" />
                                </svg>
                                <div class="flex-grow h-full -ml-px bg-yellow-200 rounded-md rounded-l-none"></div>
                            </div>
                            <span class="relative text-yellow-500 uppercase font-semibold pr-px">
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>#genres<span>&nbsp;</span>
                            </span>
		                </div>
	                </div>
                    <p className="text-gray-900 w-4/5 mt-8">{series.description}</p>
                    <div className="mt-12">
                        <div className="flex mt-4">
                            <div className="mr-8">
                                <div>Nombre de saisons : {series.seasons}</div>
                            </div>
                            <div className="mr-8">
                                <div>Nombre d'épisodes : {series.episodes}</div>
                            </div>
                            <div className="mr-8">
                                <div>Durée des épisodes : {series.length} minutes</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12">
                        <div className="error">
                                { errors &&
                                <p severity="error">{errors}</p>
                                }
                            </div>
                            <div className="success">
                                {archiveSuccess &&
                                    <p className="text-center fs-5">La série a bien été archivé.</p>
                                }
                            </div>
                            <div className="success">
                                {addSuccess &&
                                    <p className="text-center fs-5">La série a bien été ajouté.</p>
                                }
                            </div>
                            <div className="inline-flex gap-5">
                            <button onClick={handleArchive} className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg text-white hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"> Archiver la série</button>
                            <button onClick={addShow} className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg text-white hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"> Ajouter la série</button>
                            <button onClick={getEpisodes} className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg text-white hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"> Voir les épisodes</button>
                        </div></div>
                        
                </div>
            </div>
            
                <div className="mt-5 p-3 bg-light pb-5">
                    {
                        printSeason()
                    }
                </div>
            </div>


                    

            
    );
}

export default Series;