import axios from "axios";
import {useEffect, useState} from "react";
import Hero2 from "../Hero2/Hero2";
import {Link} from "react-router-dom";

const Allmovies = () => {

    let betaseriesURLMovies = process.env.REACT_APP_API_URL+"movies/search";
    const [movies, setMovies] = useState([]);

    const getMovies = () => {

        axios.get(betaseriesURLMovies, {
            params: {
                key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                v: 3.0,
                nbpp: "100",
            }
        }).then(response => {
            setMovies(response.data.movies);
        }).catch(error => {
        });
    }

    useEffect(() => {
        getMovies();
    }, [getMovies]);


    return (
        <div>
            <Hero2/>
            <div className="min-h-screen py-32 px-10 bg-cover bg-no-repeat md:bg-bottom" >
            <h2 className="mb-2 text-center text-3xl font-extrabold leading-tight text-gray-900">Tous les films</h2>
            <p className="mb-20 text-center text-lg text-gray-500">Ici, vous pouvez ajouter vos contenus visionnés à votre compte.</p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl-grid-cols-4 2xl-grid-cols-5 gap-y-10"> 

            {
                    movies.length !== 0 && (
                        movies.map((item) => {
                    return (
                        <div className="container mx-auto shadow-lg rounded-lg max-w-md hover:shadow-2xl transition duration-300 mt-8 bg-white">
                            <Link to={"/movies/"+item.id}>
                                <img src={item.poster} alt="titre" className="hover:opacity-75 rounded-t-lg transition ease-in-out duration-150"/>
                            </Link>
                            <div className="mt-2 p-6">
                            <Link to={"/movies/"+item.id} className="text-lg mt-2 hover:text-gray-300">{item.title}</Link>
                            <div className="flex items-center text-gray-400 text-sm mt-1">
                                <svg className="fill-current text-orange-500 w-4" viewBox="0 0 24 24">
                                <g data-name="Layer 2">
                                    <path
                                    d="M17.56 21a1 1 0 01-.46-.11L12 18.22l-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18z"
                                    data-name="star"></path>
                                </g>
                                </svg>
                                <span className="ml-1">Note : {item.notes.mean.toFixed(2)} / 5</span>
                                <span className="mx-2">|</span>
                                <span className="ml-1">Followers : {item.followers}</span>
                                <span className="mx-2">|</span>
                                <span className="ml-1">Date de sortie : {item.production_year}</span>
                            </div>
                            <p className="mt-4 text-md text-gray-600">{item.synopsis.length > 250 ?`${item.synopsis.substring(0, 240)}...` : item.synopsis}</p>
                            <div className="flex justify-between items-center">
                                <div className="mt-4 flex items-center space-x-4 py-6">
                                    <Link to={"/movies/"+item.id} className="inline-flex items-center font-semibold text-gray-600 md:mb-2 lg:mb-0 hover:text-gray-400 " title="read more"> En savoir plus » </Link>
                                </div>
                                <div className="p-6 bg-gray-600 rounded-full h-4 w-4 flex items-center justify-center text-2xl text-white mt-4 shadow-lg hover:bg-gray-400 focus:shadow-outline focus:outline-none cursor-pointer">
                                    <Link to={"#"}>
                                    +
                                    </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        )
                    })
                    )
                }
                    </div>
                </div>
            </div>
    )
}


        

export default Allmovies;