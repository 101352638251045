import axios from "axios";
import React, { useEffect, useState } from "react";


const FriendsList = () => {

    const [friends, setFriends] = useState([]);
    const [deleteSuccess, setDeleteSuccess] = useState(false)

    const handleDelete = async (event) => {
        event.preventDefault();
        let idFriend= event.target.dataset.idfriend
        let betaseriesURL = process.env.REACT_APP_API_URL+"friends/friend";
        console.log(idFriend)
        await axios.delete(betaseriesURL, {
            params: {
                key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                v: 3.0,
                id: idFriend,
                token: localStorage.getItem("token")
            }
        }).catch((error) => {
            console.log(error.response.data.errors[0].text)
        });
        setDeleteSuccess(true);
    };

    useEffect(() => {
        const getFriends = async () => {

            let betaseriesURL = process.env.REACT_APP_API_URL+"friends/list";

            const response =  await axios.get(betaseriesURL, {
                params: {
                    key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                    v: 3.0,
                    token: localStorage.getItem("token")
                }
            })
            setFriends(response.data.users)
        }
        getFriends()
    }, [])

    return (
        <div>
        <div className="min-h-screen pt-32 px-10 bg-cover bg-no-repeat md:bg-bottom">
            <div>
                <h2 className="mb-2 text-center text-3xl font-extrabold leading-tight text-gray-900">Mes Amis</h2>
                <p className="mb-20 text-center text-lg text-gray-500">Ici, vous pouvez gérer la liste de vos amis.</p>
                { friends.length === 0 && (                      
                <div>
                <p className="text-center text-lg text-gray-500">Vous n'avez ajouté aucuns de vos amis à votre compte.</p>
                <p className="mb-20 text-center text-lg text-gray-500">Ajoutez les à votre compte pour que vous puissiez vous partager vos visionnages.</p>
                </div>)}
                {deleteSuccess &&
                <p>L'ami a bien été supprimé.</p> }
                <div className="d-flex flex-wrap bg-light">
                    <table className="border-collapse w-full">
                        <thead>
                            <tr>
                                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Pseudonyme</th>
                                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Points d'experiences</th>
                                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Actions</th>
                            </tr>
                        </thead>
                        { friends.length !== 0 && ( friends.map((item, index) => {
                        return  <tbody>
                            <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Pseudo</span>
                                {item.login}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Experiences</span>
                                    {item.xp}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Actions</span>
                                    <a href="/" data-idfriend={item.id} onClick={handleDelete} className="px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 text-white">Supprimer</a>
                                </td>
                            </tr>
                        </tbody> }))}
                    </table>
                </div>
            </div>
        </div>
    </div> )}
    
    export default FriendsList;