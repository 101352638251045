import React, {useEffect, useState} from "react";
import axios from "axios";


const Movies = ({ match }) => {

    let id = match.params.id;

    const [movie, setMovie] = useState([]);
    const [errors, setErrors] = useState([]);
    const [favoriSuccess, setFavoriSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const handleFavori = () => {
        let betaseriesURL = process.env.REACT_APP_API_URL+"movies/movie";
        const token = localStorage.getItem("token");
        const header = {
            'Authorization': `Bearer ${token}`
        }
        axios.post(betaseriesURL, {
            key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
            v: 3.0,
            id: id,
            state:1,
        }, {headers: header}).then(res =>{
            setFavoriSuccess(true);
            console.log(res.data)
        }).catch((error) => {
            setErrors(error.response.data.errors[0].text);
        });
    };

    useEffect(() => {
        const getMovie = async () => {

            let betaseriesURL = process.env.REACT_APP_API_URL+"movies/movie";

            const response =  await axios.get(betaseriesURL, {
                params: {
                    key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
                    v: 3.0,
                    id: id
                }
            })
            setMovie(response.data.movie)
            setIsLoading(false)
        }
        getMovie()
    }, [id])

    return isLoading ? <span>chargement</span> : (


<div className="movie-info items-center">
            <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row">
                <div className="w-5/6 py-3">
                    <img className="rounded-xl md:w-96" src={movie.poster} alt="Nom série"/>
                </div>
                <div className="md:ml-24">
                    <h2 className="text-4xl font-semibold p-3">{movie.original_title}</h2>
                    <div className="flex flex-wrap items-center text-gray-400 text-sm">
                        <svg className="fill-current text-orange-500 w-4" viewBox="0 0 24 24">
                            <g data-name="Layer 2">
                                <path d="M17.56 21a1 1 0 01-.46-.11L12 18.22l-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18z" data-name="star"></path>
                            </g>
                        </svg>
                        <span className="ml-1">Note : {movie.notes.mean.toFixed(2)} / 5</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Followers : {movie.followers}</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Date de production : {movie.production_year}</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Date de sortie : {movie.release_date}</span>
                    </div>
                    <div className="flex flex-wrap items-center text-gray-400 text-sm">
                        <span className="ml-1">Langue : {movie.language}</span>
                        <span className="mx-2">|</span>
                        <span className="ml-1">Réalisateurs : {movie.director}</span>
                    </div>
                    <div className="py-4">
                        <div class="inline-block relative py-1 text-sm">
                            <div class="absolute inset-0 text-yellow-200 flex">
                                <svg height="100%" viewBox="0 0 50 100">
                                    <path
                                        d="M49.9,0a17.1,17.1,0,0,0-12,5L5,37.9A17,17,0,0,0,5,62L37.9,94.9a17.1,17.1,0,0,0,12,5ZM25.4,59.4a9.5,9.5,0,1,1,9.5-9.5A9.5,9.5,0,0,1,25.4,59.4Z"
                                        fill="currentColor" />
                                </svg>
                                <div class="flex-grow h-full -ml-px bg-yellow-200 rounded-md rounded-l-none"></div>
                            </div>
                            <span class="relative text-yellow-500 uppercase font-semibold pr-px">
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{movie.genres}<span>&nbsp;</span>
                            </span>
		                </div>
	                </div>
                    <div className="text-gray-400">
                        <span className="ml-2"></span>
                    </div>
                    <p className="text-gray-900 w-4/5 mt-8">{movie.synopsis}</p>
                    <p className="text-gray-900 w-4/5 mt-8">{movie.tagline}</p>
                    <div className="mt-12">
                        <div className="error">
                            { errors &&
                                <p severity="error">{errors}</p>
                            }
                        </div>
                        <div className="success">
                            {favoriSuccess &&
                            <p className="text-center fs-5">Le film a bien été ajouté aux favoris.</p>
                            }
                        </div>
                        <button onClick={handleFavori} className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg text-white hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"> Ajouter au favoris</button>
            </div>
                       


                </div>
            </div>
            
            </div>


                    

            
    );
};
export default Movies;