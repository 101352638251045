import React from 'react';


const Hero5 = () => {
    return (
    <section className="text-blueGray-700 bg-black flex items-center text-gray-50">
      <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
        <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
          <h1 className="mb-8 text-2xl font-black tracking-tighter md:text-5xl title-font">{localStorage.getItem('Login')}, Ne ratez rien des sorties cinéma et séries les plus attendues.</h1>
            <ul className="mb-8 text-base leading-relaxed text-left text-blueGray-600 text-xl "> 
              <li className="p-2">Qu’il s’agisse d’informations dévoilées au sujet de films cultes ou bien des dernières news sur les dates et castings des blockbusters à paraître.</li>
              <li className="p-2">Nous passons au crible l’essentiel des infos à ne pas louper dans le monde du septième art.</li>
              <li className="p-2">Retrouvez notamment les actualités relatives aux films Marvel, aux séries événements, aux nouveautés manga ou encore aux dernières sorties Netflix à ne pas manquer.</li>
            </ul>
        </div>
        <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
        <img className="object-cover object-center rounded-lg " alt="hero" src={process.env.PUBLIC_URL + '../../../../img/cine.png'}/>
        </div>
      </div>
    </section>
    )
}


export default Hero5;