import React from 'react';
import Hero1 from './Hero1/Hero';

const Home = () => {
  
    return (
        <div>
        <Hero1/>
        <section className="text-blueGray-700 bg-gray-100 flex items-center">
        <div className="container flex flex-col items-center px-5 py-10 mx-auto md:flex-row lg:px-28">
          <div className="flex flex-col items-start text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
            <h1 className="mb-8 text-2xl font-black tracking-tighter md:text-5xl title-font"> Bienvenue {localStorage.getItem('Login')} </h1>
            <ul className="mb-8 text-base leading-relaxed text-left text-xl "> 
                <li className="p-1">Vous retrouverez sur Previously ON. </li>
                <li className="p-1">Les films et séries de vos chaînes et de vos offres VOD préférées. </li>
                <li className="p-1">Réunis au même endroit et ajouter vos contenus visionnés à votre compte. </li>
            </ul>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:pb-2 md:w-3/5">
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chOCS._CB452270451_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chStarz._CB1198675309_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chTFOU._CB1198675309_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chmonscience._CB1198675309_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chGulli._CB1198675309_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chActionMax._CB1198675309_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chGEO._CB1198675309_.jpg" alt="TV"/>
            <img src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chMGM._CB1198675309_.jpg" alt="TV"/>
            <img className="md:block hidden" src="https://m.media-amazon.com/images/G/08/digital/video/merch/subs/mlpmagnet/mlpmagnet_chMubi._CB1198675309_.jpg" alt="TV"/>
        </div>
        </div>
      </section>

      
     
      
      
      
      
      
      
      
      

        </div> 
    );
}

export default Home;