import React from 'react';


const Hero3 = () => {
    return (
<section className="text-blueGray-700 bg-black flex items-center text-gray-50">
<div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
    <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
        <img className="object-cover object-center rounded-lg " alt="hero" src="https://occ-0-5556-3662.1.nflxso.net/dnm/api/v6/19OhWN2dO19C9txTON9tvTFtefw/AAAABVxdX2WnFSp49eXb1do0euaj-F8upNImjofE77XStKhf5kUHG94DPlTiGYqPeYNtiox-82NWEK0Ls3CnLe3WWClGdiJP.png?r=5cf"/>
      </div>
  <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
    <h1 className="mb-8 text-2xl font-black tracking-tighter md:text-5xl title-font"> Du contenu pour toute la famille. </h1>
    <ul className="mb-8 text-base leading-relaxed text-left text-blueGray-600 text-xl "> 
        <li className="p-2">Enregistrez vos programmes préférés et ayez toujours quelque chose à regarder. </li>
        <li className="p-2">Gérez vos visionnages et ceux de votre famille… </li>
    </ul>
  </div>
</div>
</section>
    )
}


export default Hero3;