import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";

  const Hero1 = () => {

    const [isConnected, setIsConnected] = useState(null);
    const history = useHistory();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) setIsConnected(true);
    }, [history]);

    return (
        <section className="text-blueGray-700 bg-black flex items-center text-gray-50">
        <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
          <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
            <h1 className="mb-8 text-2xl font-black tracking-tighter md:text-5xl title-font"> Previously ON. </h1>
            <ul className="mb-8 text-base leading-relaxed text-left text-blueGray-600 text-xl "> 
                <li className="p-1">Cinéma, Séries TV, Vidéos, DVD et VOD. </li>
                <li className="p-1">Cet outil est le meilleur allié de vos séries, et de vos films. </li>
                <li className="p-1">Suivez toutes vos séries et films préférés depuis un seul endroit. </li>
            </ul>
            {!isConnected && (
            <div className="flex flex-col justify-center lg:flex-row">
              <Link to="/login" className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"> Connexion </Link>
              <p className="mt-2 text-sm text-left text-blueGray-600 md:ml-6 md:mt-0"> Si vous n'êtes pas encore inscrit, <br className="hidden lg:block"/>
                <a href="https://www.betaseries.com/inscription/" className="inline-flex items-center font-semibold text-gray-600 md:mb-2 lg:mb-0 hover:text-gray-400 " title="read more"> Dirigez-vous ici » </a>
              </p>
            </div>)}
          </div>
          <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
            <img className="object-cover object-center rounded-lg " alt="hero" src={process.env.PUBLIC_URL + '../../img/prev.png'}/>
          </div>
        </div>
      </section>
    )
}


export default Hero1;


