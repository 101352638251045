import React from 'react';
import './Error.css';

const Error = () => {

    return (
        <div className="er">
            <h1 className="error">404</h1>
            <p className="err">Oups! Page d'erreur..<br></br>Pas de panique.</p>
            <div className="align">
                <a className="backToHome" href="/">
                    Retour à l'accueil 
                </a>
            </div> 
        </div> 
    );
}

export default Error;

