import React, {useEffect, useState} from "react";
import axios from "axios";
import Hero5 from "../Hero5/Hero5";

const News = ({ match }) => {

    let config = {'Authorization': process.env.REACT_APP_PRESVIOUSLYON_API_KEY};
    let betaseriesURL = "https://api.betaseries.com/news/last";
    let tailored;
  
    if (match.params.tailored === "true"){
        tailored = true;
    }else{
        tailored = false;
    }
    const [news, setNews] = useState([]);

    useEffect(() => {
        
        axios.get(betaseriesURL, {
            headers: config,
            params: {
                tailored: tailored,
                number: 200,
                client_id: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
            }
        }).then (response => {
            setNews(response.data.news);
        }).catch(error => {
        });
    }, []);

    return (
        <div>
            <Hero5/>
        <div className="min-h-screen py-32 px-10 bg-cover bg-no-repeat md:bg-bottom">
            <h2 className="mb-2 text-center text-3xl font-extrabold leading-tight text-gray-900">Toutes l'actualités</h2>
            <p className="mb-20 text-center text-lg text-gray-500">Ici, vous trouverez toute l'actualités sur vos programmes.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 xl-grid-cols-4 gap-y-10 gap-x-6 mb-16">
            {
                news.map((item, index) => {
                    return  <div key={index}>
                        <div className="container mx-auto shadow-lg rounded-lg max-w-md hover:shadow-2xl transition duration-300 mt-8 bg-white">
                                        <a href={item.url}>
                                            <img src={item.picture_url} alt="titre" className="hover:opacity-75 rounded-t-lg transition ease-in-out duration-150"/>
                                        </a>
                                        <div className="mt-2 p-6">
                                            <a href={item.url} className="text-lg mt-2 hover:text-gray-300">{item.title}</a>
                                          
                                <div className="flex items-center text-gray-400 text-sm mt-1">
                                    <span className="ml-1">Date de publication : {item.date}</span>
                                </div>
                            <p className="mt-4 text-md text-gray-600">{item.description}</p>
                                <div className="flex justify-between items-center">
                                <div className="flex justify-center pt-4 max-w-xl mx-auto">
                                    <button onClick={item.url} className="flex items-center px-6 py-2 mt-auto font-semibold text-whitetransition duration-500 ease-in-out transform bg-gray-600 rounded-lg text-white hover:bg-gray-400 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">En savoir plus</button>
                                    </div>
                                </div>
                                </div>
                            </div>

                            </div>
                })
            }
            </div>
        </div>
    </div>
    )
}

export default News;