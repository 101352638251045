import React from 'react';


const Hero4 = () => {
    return (
    <section className="text-blueGray-700 bg-black flex items-center text-gray-50">
      <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
        <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
          <h1 className="mb-8 text-2xl font-black tracking-tighter md:text-5xl title-font">Découvrez une meilleure manière de découvrir.</h1>
            <ul className="mb-8 text-base leading-relaxed text-left text-blueGray-600 text-xl "> 
              <li className="p-2">{localStorage.getItem('Login')}, est-ce que vous saviez que le moteur de recherche de Previously ON est particulièrement performant ? </li>
              <li className="p-2">Tapez “Cary Grant” par exemple, et cela vous affichera instantanément les programmes correspondants, qu’ils soient en live, en Replay ou à venir.</li>
              <li className="p-2">Essayez aussi avec le nom d’un acteur ou d’un réalisateur.</li>
              <li className="p-2">Vous allez découvrir des émissions, des films et des séries dont vous ne soupçonniez même pas l’existence.</li>
            </ul>
        </div>
        <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
          <img className="object-cover object-center rounded-lg " alt="hero" src={process.env.PUBLIC_URL + '../../img/plane.png'}/>
        </div>
      </div>
    </section>
    )
}


export default Hero4;