import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import md5 from "md5";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };


    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        let betaseriesURL = process.env.REACT_APP_API_URL+"members/auth";

        await axios.post(betaseriesURL, {
            key: process.env.REACT_APP_PRESVIOUSLYON_API_KEY,
            v: 3.0,
            login: email,
            password: md5(password),
        }).then((response) => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('Login', response.data.user.login);
            localStorage.setItem('Id', response.data.user.id);
            localStorage.setItem('name', response.data.user.name);
            window.location.href="/";
        })
        .catch((error) => {
            console.log(error.response.data.errors[0].text)
            setErrors(error.response.data.errors[0].text);
        });
    };

  
    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token) history.push("/");
    }, [history]);

    return (
    <div>
            <section className="min-h-screen flex items-stretch text-white ">
        <div className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center" style={{ backgroundImage: `url(${"https://assets.nflxext.com/ffe/siteui/vlv3/5a27cb25-33a9-4bcc-b441-95fefabcbd37/b42206c6-4cfa-43a2-903c-35aff1fedde5/FR-fr-20210823-popsignuptwoweeks-perspective_alpha_website_large.jpg"})` }} >
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full px-24 z-10">
                <h1 className="text-5xl font-bold text-left tracking-wide">Connectez-vous à votre compte Betaseries.</h1>
                <p className="text-3xl my-4">Cet outil est le meilleur allié de vos séries, et de vos films.</p>
            </div>
        </div>
        <div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0">
            <div className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center" style={{ backgroundImage: `url(${"https://assets.nflxext.com/ffe/siteui/vlv3/5a27cb25-33a9-4bcc-b441-95fefabcbd37/b42206c6-4cfa-43a2-903c-35aff1fedde5/FR-fr-20210823-popsignuptwoweeks-perspective_alpha_website_large.jpg"})` }} >
                <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            </div>
            <div className="w-full py-6 z-20">
                <h1 className="my-6">
                <img className="mx-auto h-12 w-auto" src={process.env.PUBLIC_URL + './img/prev.png'} alt="logo"/>
                </h1>
                <a href="https://www.betaseries.com/inscription/" className="font-medium text-gray-600 hover:text-gray-400">Inscrivez-vous ici</a>
                <form onSubmit={handleLogin} className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto mt-8 space-y-6" action="#" method="POST">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" className="sr-only">Adresse Mail</label>
                            <input id="email-address" value={email} onChange={handleEmail} name="email" type="email" autocomplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Adresse Mail"/>
                        </div>
                        <div>
                            <label for="password" className="sr-only">Mot de passe</label>
                            <input id="password" value={password} onChange={handlePassword} name="password" type="password" autocomplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Mot de passe"/>
                        </div>
                    </div>
                    <div className="text-right text-gray-400 hover:underline hover:text-gray-100">
                        <a href="#">Mot de passe oublié ?</a>
                    </div>
                    <div className="px-4 pb-2 pt-4">
                        <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white font-semibold bg-gray-600 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg className="h-5 w-5 text-gray-500 group-hover:text-gray-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                            </svg>
                            </span>
                            Connexion
                        </button>
                        { errors &&
                    <p className="relative leading-normal pt-6 text-red-700" role="alert">{errors} </p>
                    }
                    </div>
                </form>
            </div>
        </div>
    </section>
        </div>


    );
};

export default Login;